export default [
  // *===============================================---*
  // *--------- Orders -------------------------------*
  // *===============================================---*
  {
    path: '/orders/list',
    name: 'orders.list',
    component: () => import('@/views/models/orders/list/OrderList.vue'),
    meta: {
      pageTitle: 'modules.orders.p_c',
      breadcrumb: [
        {
          text: 'modules.orders.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/view/:id',
    name: 'orders.view',
    component: () => import('@/views/models/orders/add/OrderAdd.vue'),
    meta: {
      pageTitle: 'modules.orders.pages.view',
      contentClass: 'ecommerce-application',
      navActiveLink: 'orders.list',
      breadcrumb: [
        {
          text: 'modules.orders.p_c',
          to: { name: 'orders.list' },
        },
        {
          text: 'modules.orders.pages.view',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/add/',
    name: 'orders.add',
    component: () => import('@/views/models/orders/add/OrderAdd.vue'),
    meta: {
      pageTitle: 'modules.orders.pages.add',
      contentClass: 'ecommerce-application',
      navActiveLink: 'orders.list',
      breadcrumb: [
        {
          text: 'modules.orders.p_c',
          to: { name: 'orders.list' },
        },
        {
          text: 'modules.orders.pages.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/list',
    name: 'orders.list',
    component: () => import('@/views/models/orders/list/OrderList.vue'),
    meta: {
      pageTitle: 'modules.orders.p_c',
      breadcrumb: [
        {
          text: 'modules.orders.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/carts/:step',
    name: 'abandonedCarts.list',
    component: () => import('@/views/models/abandoned_carts/list/CartList.vue'),
    meta: {
      pageTitle: 'modules.abandoned_carts.p_c',
      breadcrumb: [
        {
          text: 'modules.abandoned_carts.pages.list',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      // TODO: Change breadcrumb to the correct one
      if (['products', 'contact', 'shipping_address', 'shipping_method', 'payment', 'checkout'].includes(to.params.step)) next()
      else next({ name: 'error-404' })
    },
  },
]
