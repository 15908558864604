export default [
  // *===============================================---*
  // *--------- Marketing Notifications -------------------------------*
  // *===============================================---*
  {
    path: '/marketing-notifications/list',
    name: 'notification_campaigns.list',
    component: () => import('@/views/models/notification_campaigns/list/List.vue'),
    meta: {
      pageTitle: 'modules.notification_campaigns.p_c',
      breadcrumb: [
        {
          text: 'modules.notification_campaigns.pages.list',
          active: true,
        },
      ],
      routeBreadcrumb: route => {
        const isAbandonedCartReminder = route.query.entity_type === 'CART'
        return [
          {
            text: isAbandonedCartReminder ? 'modules.notification_campaigns.pages.abandoned_carts_reminders' : 'modules.notification_campaigns.pages.list',
            active: !isAbandonedCartReminder,
            to: isAbandonedCartReminder ? { name: 'abandonedCarts.list', params: { step: 'products' } } : null,
          },
          ...(isAbandonedCartReminder && route.query.entities ? [
            {
              text: 'modules.notification_campaigns.pages.abandoned_carts_reminders_carts',
              textParams: { id: route.query.entities },
              active: true,
            },
          ] : []),
        ]
      },
    },
  },
  // {
  //   path: '/marketing-notifications/view/:id',
  //   name: 'notification_campaigns.view',
  //   component: () => import('@/views/models/notification_campaigns/view/CampaignView.vue'),
  //   meta: {
  //     pageTitle: 'modules.notification_campaigns.pages.view',
  //     navActiveLink: 'notification_campaigns.view',
  //     breadcrumb: [
  //       {
  //         text: 'modules.notification_campaigns.p_c',
  //         to: { name: 'notification_campaigns.list' },
  //       },
  //       {
  //         text: 'modules.notification_campaigns.pages.view',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  //
  // {
  //   path: '/marketing-notifications/add/',
  //   name: 'notification_campaigns.add',
  //   component: () => import('@/views/models/notification_campaigns/add/Add.vue'),
  //   meta: {
  //     pageTitle: 'modules.notification_campaigns.pages.add',
  //     navActiveLink: 'notification_campaigns.list',
  //     breadcrumb: [
  //       {
  //         text: 'modules.notification_campaigns.p_c',
  //         to: { name: 'notification_campaigns.list' },
  //       },
  //       {
  //         text: 'modules.notification_campaigns.pages.add',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  // {
  //   path: '/marketing-notifications/edit/:id',
  //   name: 'notification_campaigns.edit',
  //   component: () => import('@/views/models/notification_campaigns/add/Add.vue'),
  //   meta: {
  //     pageTitle: 'modules.notification_campaigns.pages.edit',
  //     navActiveLink: 'notification_campaigns.list',
  //     breadcrumb: [
  //       {
  //         text: 'modules.notification_campaigns.p_c',
  //         to: { name: 'notification_campaigns.list' },
  //       },
  //       {
  //         text: 'modules.notification_campaigns.pages.edit',
  //         active: true,
  //       },
  //     ],
  //   },
  // },

  // *===============================================---*
  // *------------- Notification Templates -------------*
  // *===============================================---*
  {
    path: '/notification-templates/list',
    name: 'notification_templates.list',
    component: () => import('@/views/models/notification_templates/list/List.vue'),
    meta: {
      pageTitle: 'modules.notification_templates.p_c',
      breadcrumb: [
        {
          text: 'modules.notification_templates.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/notification-templates/add/',
    name: 'notification_templates.add',
    component: () => import('@/views/models/notification_templates/add/Add.vue'),
    meta: {
      pageTitle: 'modules.notification_templates.pages.add',
      navActiveLink: 'notification_templates.list',
      breadcrumb: [
        {
          text: 'modules.notification_templates.p_c',
          to: { name: 'notification_templates.list' },
        },
        {
          text: 'modules.notification_templates.pages.add',
          active: true,
        },
      ],
    },
  },
  {
    path: '/notification-templates/edit/:id',
    name: 'notification_templates.edit',
    component: () => import('@/views/models/notification_templates/add/Add.vue'),
    meta: {
      pageTitle: 'modules.notification_templates.pages.edit',
      navActiveLink: 'notification_templates.list',
      breadcrumb: [
        {
          text: 'modules.notification_templates.p_c',
          to: { name: 'notification_templates.list' },
        },
        {
          text: 'modules.notification_templates.pages.edit',
          active: true,
        },
      ],
    },
  },
]
