export default [
  // *===============================================---*
  // *--------- Settings -------------------------------*
  // *===============================================---*
  {
    path: '/shipping-settings',
    name: 'shippingSettings',
    component: () => import('@/views/models/shipping_methods/list/Index.vue'),
    meta: {
      pageTitle: 'modules.shipping_methods.p_c',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.shipping_methods.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/couriers/:courier/:action',
    name: 'courierAction',
    component: () => import('@/views/models/couriers/actions/Index.vue'),
    meta: {
      pageTitle: 'modules.shipping_methods.p_c',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.shipping_methods.actions.pickup_requests',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (['WASSALHA', 'BOSTA'].includes(to.params.courier) && ['pickups'].includes(to.params.action)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/payment-settings',
    name: 'paymentSettings',
    component: () => import('@/views/models/payment_methods/list/Index.vue'),
    meta: {
      pageTitle: 'modules.payment_methods.p_c',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.payment_methods.pages.list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/:category/:subcategory',
    name: 'generalSettings',
    component: () => import('@/views/models/settings/general-settings/Index.vue'),
    meta: {
      pageTitle: 'modules.settings.p_c',
      breadcrumb: [
        {
          text: 'modules.settings.pages.general',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (['store'].includes(to.params.category) && ['general', 'domain', 'checkout', 'vat', /* 'account', */'social', 'pages', 'currencies', 'notifications', 'seo'].includes(to.params.subcategory)) next()
      else if (['layout'].includes(to.params.category)) next()
      else if (['store'].includes(to.params.category) && ['menus'].includes(to.params.subcategory)) next({ name: 'menus' })
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/theme/editor',
    name: 'themeEditor',
    component: () => import('@/views/models/theme-editor/Index.vue'),
    meta: {
      layout: 'editor',
      pageTitle: 'modules.layout_settings.p_s',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.layout_settings.pages.main',
          active: true,
        },
      ],
    },
  },
  {
    path: '/layout-settings',
    name: 'layoutSettings',
    component: () => import('@/views/models/settings/layout-settings/IndexV2.vue'),
    meta: {
      pageTitle: 'modules.settings.p_c',
      contentClass: 'ecommerce-application',
      breadcrumb: [
        {
          text: 'modules.layout_settings.p_c',
          active: true,
        },
      ],
    },
  },
]
